import "core-js/modules/es.error.cause.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { addFwzAccounts, updateFwzAccounts } from '@/api/account';
import { fwzjobList } from '@/utils/database';
const formLabelWidth = '150px';
export default {
  __name: 'modal-handle',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const fwzjobListData = fwzjobList;
    const emit = __emit;
    const dialogVisible = ref(false); // label 的宽度

    const titlevalue = ref('编辑'); // 手机号码校验 phone

    const phonevalidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        setTimeout(() => {
          if (!/^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[1,8,9])\d{8}$/.test(value)) {
            callback(new Error('请输入正确的号码'));
          } else {
            callback();
          }
        }, 200);
      }
    };

    const rules = ref({
      // 账号
      account: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      //   密码
      password: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      // 手机号
      phone: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }, {
        validator: phonevalidator,
        trigger: 'blur'
      }],
      // 服务站
      storeId: [{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }],
      // 持有者姓名
      name: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      // 岗位
      job: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      // 状态
      state: [{
        required: true,
        message: '请选择',
        trigger: 'change'
      }]
    }); // 状态选择框

    const stateOptions = [{
      value: 1,
      label: '启用'
    }, {
      value: 2,
      label: '停用'
    }];
    const formRef = ref();

    const generateFrom = () => {
      return {
        id: null,
        // ID
        account: null,
        // 账号
        password: null,
        // 密码
        storeId: null,
        // 服务站ID
        phone: null,
        // 手机号
        name: null,
        // 持有者姓名
        job: null,
        // 岗位
        menu: null,
        // 一级菜单id 拼接字符串
        menu_children: null,
        //  二级菜单id 拼接字符串
        state: 1 // 状态

      };
    }; // StorelistData 服务站列表


    const StorelistData = ref([]);
    const accountInfo = ref(); // 当前账号信息

    const formModel = reactive(generateFrom());

    const showModal = data => {
      console.log('fwzjobListData---', fwzjobListData);
      var record = data === null || data === void 0 ? void 0 : data.record;
      resetFormModel(); // 重置表单

      StorelistData.value = data ? data.StorelistData : [];

      if (record) {
        accountInfo.value = record;
        titlevalue.value = '编辑';
        const Keys = Object.keys(formModel);

        for (var key in record) {
          if (Keys.indexOf(key) > -1) {
            formModel[key] = record[key];
          }
        }
      } else {
        titlevalue.value = '新增';
      }

      setServeId();
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    }; // 重置表单


    const resetFormModel = () => {
      const initialForm = generateFrom();
      Object.keys(formModel).forEach(key => {
        formModel[key] = initialForm[key];
      });
    }; // 新增/ 编辑 接口


    const FwzAccountsFn = async () => {
      console.log('accountInfo---', accountInfo.value);
      let resData = null;

      if (titlevalue.value === '编辑') {
        var newformModel = JSON.parse(JSON.stringify(formModel)); // 需判断是否更改，有更改则传，无更改则不传  accountInfo: 当前账号信息

        if (newformModel.account !== accountInfo.value.account) {
          newformModel.newAccount = formModel.account;
        }

        delete newformModel.account;
        console.log('newformModel---', newformModel); // 有数值是编辑

        resData = await updateFwzAccounts(newformModel);
      } else if (titlevalue.value === '新增') {
        console.log('到新增'); // 没有数值是新增

        resData = await addFwzAccounts(formModel);
      }

      if (resData && resData.status === 'success') {
        ElMessage({
          message: `${titlevalue.value}成功！`,
          type: 'success'
        });
        emit('refresh');
        hideModal();
      } else {
        ElMessage({
          message: `${resData.description}`,
          type: 'error'
        });
      }
    }; // 保存按钮


    const submitForm = async () => {
      if (!formRef.value) return;
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          FwzAccountsFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    }; // 设置默认服务站id


    const userInfo = ref(JSON.parse(localStorage.getItem('userInfo')));

    const setServeId = async () => {
      if (userInfo.value.storeId) {
        formModel.value.storeId = userInfo.value.storeId;
      }
    }; // 服务站选择


    const storeChange = val => {
      formModel.storeId = val[1];
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => dialogVisible.value = $event),
        title: titlevalue.value,
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[7] || (_cache[7] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          rules: rules.value,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "账号",
                prop: "account",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.account,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.account = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "密码",
                prop: "password",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.password = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "手机号",
                prop: "phone",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "number",
                  modelValue: formModel.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.phone = $event),
                  modelModifiers: {
                    number: true
                  },
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "持有者姓名",
                prop: "name",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.name = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "所属服务站",
                prop: "storeId",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_cascader, {
                  modelValue: formModel.storeId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.storeId = $event),
                  options: StorelistData.value,
                  onChange: storeChange,
                  disabled: userInfo.value.storeId ? true : false,
                  clearable: "",
                  style: {
                    "width": "100%"
                  }
                }, null, 8, ["modelValue", "options", "disabled"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "岗位",
                prop: "job",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.job,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.job = $event),
                  placeholder: "请选择",
                  size: "large"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fwzjobList), item => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "状态",
                prop: "state",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.state,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.state = $event),
                  placeholder: "选择状态",
                  size: "large"
                }, {
                  default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(stateOptions, item => {
                    return _createVNode(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value
                    }, null, 8, ["label", "value"]);
                  }), 64))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]);
    };
  }

};